import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section";
import {
  DesignOrderIcon,
  FreeDelivery,
  ManufacturingIcon,
  OrderConfirmationIcon,
} from "../components/Icons";
import {
  HeroBanner,
  HeroTitle,
  HeroCaption,
  HeroContent,
  HeroLeft,
  HeroRight,
  HeroCard,
} from "../components/HeroBanner";
import JoinForm from "../components/JoinForm";

const CardTimelineOuter = styled.div`
  position: relative;
  counter-reset: section;
  padding-bottom: 40px;
  .card {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      border: dashed rgba(96, 96, 96, 0.4);
      top: 0;
      bottom: 0;
      border-width: 0 1px 0 0;
      border-radius: 0 0 0 0;
      left: 0;
      width: 0;
      @media (min-width: 768px) {
        left: 2%;
        width: 2%;
      }
      @media (min-width: 992px) {
        left: 5%;
        width: 5%;
      }
    }
    + .card {
      &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        background: #fff;
        box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.2);
        border: 3px solid #f48219;
        left: -10px;
        width: 20px;
        height: 20px;
        top: 20px;
        @media (min-width: 768px) {
          width: 30px;
          height: 30px;
          border: 5px solid #f48219;
          top: 30px;
          left: calc(2% + 5px);
        }
        @media (min-width: 992px) {
          left: calc(10% - 15px);
        }
      }
    }

    &:nth-child(1) {
      @media (min-width: 1200px) {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #f8fafb;
      }
      .info {
        padding: 40px 0 30px 30px;
        @media (min-width: 992px) {
          padding: 80px 0 60px 60px;
        }
        @media (min-width: 1200px) {
          padding: 130px 0 60px 60px;
        }
      }
      &:before {
        @media (min-width: 768px) {
          left: 0px;
          top: 0;
          bottom: 0;
          border-width: 1px 0 1px 1px;
          border-radius: 30px 0 0 30px;
          border-top: 0;
          border-top-left-radius: 0;
        }
      }
      &:after {
        content: "";
        position: absolute;
        border: dashed rgba(96, 96, 96, 0.4);
        top: 0;
        bottom: 0;
        border-width: 0 0 1px 0;
        border-radius: 0 30px 0 0;
        left: 0;
        width: 0;
        @media (min-width: 768px) {
          left: 2%;
          width: 2%;
        }
        @media (min-width: 992px) {
          left: 5%;
          width: 5%;
        }
      }
    }
    + .card {
      .info {
        &:before {
          @media (min-width: 576px) {
            counter-increment: section;
            content: "" counter(section) "";
            color: #161420;
            font-family: "Montserrat", sans-serif;
            font-size: 40px;
            line-height: 40px;
            font-weight: 700;
            position: absolute;
            left: 20px;
            top: 10px;
          }
          @media (min-width: 768px) {
            font-size: 50px;
            line-height: 50px;
            left: calc(5% + 30px);
            top: 12px;
          }
          @media (min-width: 992px) {
            font-size: 60px;
            line-height: 60px;
            top: 15px;
            left: calc(10% + 30px);
          }
        }
      }
    }
    &:nth-child(2) {
      .icon {
        background-color: #f48219;
      }
    }
    &:nth-child(3) {
      &:after {
        border-color: #e44ba4;
      }
      .icon {
        background-color: #e44ba4;
      }
    }
    &:nth-child(4) {
      &:after {
        border-color: #6f5dc6;
      }
      .icon {
        background-color: #6f5dc6;
      }
    }
    &:nth-child(5) {
      &:before {
        height: 10%;
      }
      &:after {
        border-color: #53c3ca;
      }
      .icon {
        background-color: #53c3ca;
      }
    }
  }

  .card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }

  .card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }
`;
const CardTimeline = styled.div``;
const CardTimelineInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: gray;
  border-radius: 10px;
  padding: 0 0 60px 50px;
  @media (min-width: 576px) {
    padding: 0 0 60px 100px;
  }
  @media (min-width: 768px) {
    padding: 0 0 80px 160px;
  }
  @media (min-width: 992px) {
    padding: 0 0 100px 260px;
  }
`;
const CardTab = styled.div`
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
`;
const CardTabHeader = styled.div`
  background-color: #161420;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: -1px;
  border-radius: 12px 12px 0 0;
  padding: 15px;
  @media (min-width: 576px) {
    padding: 15px 30px;
  }
  @media (min-width: 768px) {
    padding: 15px 40px;
  }
  @media (min-width: 992px) {
    padding: 20px 70px;
  }
  .text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 32px;
    }
    @media (min-width: 1600px) {
      font-size: 20px;
      line-height: 34px;
    }
  }
  .icon {
    border: 7px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f48219;
    position: absolute;
    width: 70px;
    height: 70px;
    left: -35px;
    top: 0;
    @media (min-width: 768px) {
      width: 80px;
      height: 80px;
      left: -40px;
    }
    > svg {
      fill: #fff;
      @media (max-width: 767.99px) {
        width: 30px;
      }
    }
    + .text {
      margin-left: 30px;
    }
  }
`;
const CardTabBody = styled.div`
  border: 1px solid #161420;
  border-radius: 0 0 15px 15px;
  padding: 20px;
  @media (min-width: 576px) {
    border-radius: 0 15px 15px 15px;
  }
  @media (min-width: 768px) {
    padding: 30px;
  }
  @media (min-width: 992px) {
    padding: 40px;
  }
  > p {
    font-weight: 500;
    color: #161420;
    margin-bottom: 0;
  }
`;

const OrderingProcessPage = ({ data, location }) => {
  const pageData = data.contentfulPageOrderProcess;
  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        location={location}
      />
      <Section
        bgColor="#F8FAFB"
        bgBefore="#002765"
        bgBeforeHeight="80%"
        bgBeforeWidth="100%"
      >
        <HeroBanner
          className="banner-hero"
          bgBefore="block"
          data-shadowtext="Ordering"
        >
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb>
                <Link to="/">Home</Link>
                <span>Ordering Process</span>
              </BreadCrumb>
              <HeroContent>
                <HeroLeft>
                  <HeroTitle>{pageData.heroTitle}</HeroTitle>
                </HeroLeft>
                <HeroRight>
                  <HeroCard>
                    <JoinForm location={location} />
                  </HeroCard>
                </HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>

      <Section
        ept="0"
        epb="120px"
        xpt="0"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#F8FAFB"
        className="section-order"
      >
        <Container>
          <CardTimelineOuter>
            <CardTimeline className="card">
              <CardTimelineInfo className="info">
                <SectionTitle>{pageData.orderProcessSectionTitle}</SectionTitle>
                <SectionDescription maxWidth="1170px" ml="0" mb="0">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        pageData.orderProcessSectionDescription
                          .childMarkdownRemark.html,
                    }}
                  />
                </SectionDescription>
              </CardTimelineInfo>
            </CardTimeline>

            <CardTimeline className="card">
              <CardTimelineInfo className="info">
                <CardTab>
                  <CardTabHeader>
                    <span className="icon">
                      <DesignOrderIcon />
                    </span>{" "}
                    <span className="text">
                      {pageData.orderProcess[0].process}
                    </span>
                  </CardTabHeader>
                  <CardTabBody>
                    <p>{pageData.orderProcess[0].descripiton}</p>
                  </CardTabBody>
                </CardTab>
              </CardTimelineInfo>
            </CardTimeline>

            <CardTimeline className="card">
              <CardTimelineInfo className="info">
                <CardTab>
                  <CardTabHeader>
                    <span className="icon">
                      <OrderConfirmationIcon />
                    </span>{" "}
                    <span className="text">
                      {pageData.orderProcess[1].process}
                    </span>
                  </CardTabHeader>
                  <CardTabBody>
                    <p>{pageData.orderProcess[1].descripiton}</p>
                  </CardTabBody>
                </CardTab>
              </CardTimelineInfo>
            </CardTimeline>

            <CardTimeline className="card">
              <CardTimelineInfo className="info">
                <CardTab>
                  <CardTabHeader>
                    <span className="icon">
                      <ManufacturingIcon />
                    </span>{" "}
                    <span className="text">
                      {pageData.orderProcess[2].process}
                    </span>
                  </CardTabHeader>
                  <CardTabBody>
                    <p>{pageData.orderProcess[2].descripiton}</p>
                  </CardTabBody>
                </CardTab>
              </CardTimelineInfo>
            </CardTimeline>

            <CardTimeline className="card">
              <CardTimelineInfo className="info">
                <CardTab>
                  <CardTabHeader>
                    <span className="icon">
                      <FreeDelivery />
                    </span>{" "}
                    <span className="text">
                      {pageData.orderProcess[3].process}
                    </span>
                  </CardTabHeader>
                  <CardTabBody>
                    <p>{pageData.orderProcess[3].descripiton}</p>
                  </CardTabBody>
                </CardTab>
              </CardTimelineInfo>
            </CardTimeline>
          </CardTimelineOuter>
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </Container>
      </Section>
    </Layout>
  );
};

export default OrderingProcessPage;

export const pageQuery = graphql`
  query OrderingProcessPageQuery {
    contentfulPageOrderProcess {
      metaTitle
      metaDescription
      heroTitle
      orderProcessSectionTitle
      orderProcessSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      orderProcess {
        process
        descripiton
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
